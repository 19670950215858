<template>
  <!-- <div class="dashboard"> -->
  <b-overlay :show="isLoading || showDashboardOverlay" spinner small rounded="sm" class="overlay-dashboard">
    <DeviceSelection v-if="!layouts.desktop.length" :devices="devices.data" :powerLines="powerLines" :steps="steps"
      :enabledDevices="enabledDevices" :suggestedVariants="devices.data" :selectedDevice="selectedDevice"
      :selectedWidget="selectedWidget" @handleDevices="handleDevices" @suggestedVariant="handleSuggestedVariant"
      @addDevice="addDevice" @addScatDevice="addScatDevice" @addPowerLine="addPowerLine"
      @removePowerLine="removePowerLine" @addTemplate="handleAddTemplate" @addWidget="addWidgetOnDashboard"
      @deleteTemplate="handleDeleteTemplate" @deleteDevice="handleDeleteDevice" :path="'skat'" />
    <dashboard-container :path="'skat'" v-if="layouts.desktop.length" ref="dashboardContainer" :layouts="layouts"
      :devices="devices" :catalogue="catalogue" :powerLines="powerLines" :isLoading="isLoading" :draggable="draggable"
      :resizable="resizable" @layout-updated="onLayoutUpdated" @save-widgets="saveWidgets" @remove-widget="removeWidget"
      @deleteTemplate="handleDeleteTemplate" @add-widget="addWidgetOnDashboard" @addDevice="addDevice"
      @addTemplate="handleAddTemplate" @deleteDevice="handleDeleteDevice" @layout-ready="onLayoutReady" />
    <AddDeviceModal ref="addDeviceModal" @addDevice="addDevice" />
    <AddScatDeviceModal :powerLines="powerLines" ref="addScatDeviceModal" @addScatDevice="addScatDevice" />
    <AddPowerLineModal ref="addPowerLineModal" @addPowerLine="addPowerLine" />
    <AddingWidget @addWidget="handleAddWidget" :devices="devices.data" ref="addTemplateModal" />
  </b-overlay>
  <!-- </div> -->
</template>

<script>
const scatParameters = {
  uBattery: "Напряжение АКБ, В",
  maxOscCh0: "Макс. знач. Ch0 (АЦП B), о.е.",
  maxOscCh1: "Макс. знач. Ch1 (АЦП A), о.е.",
  maxOscCh2: "Макс. знач. Ch2, о.е.",
  minOscCh0: "Мин. знач. Ch0 (АЦП B), о.е.",
  minOscCh1: "Мин. знач. Ch1 (АЦП A), о.е.",
  minOscCh2: "Мин. знач. Ch2, о.е.",
  maxOscCh0LowFreq: "Макс. знач. Ch0 Low Freq (АЦП B), о.е.",
  maxOscCh1LowFreq: "Макс. знач. Ch1 Low Freq (АЦП A), о.е.",
  maxOscCh2LowFreq: "Макс. знач. Ch2 Low Freq, о.е.",
  minOscCh0LowFreq: "Мин. знач. Ch0 Low Freq (АЦП B), о.е.",
  minOscCh1LowFreq: "Мин. знач. Ch1 Low Freq (АЦП A), о.е.",
  minOscCh2LowFreq: "Мин. знач. Ch2 Low Freq, о.е.",
  gainDa1A: "К усил chA DA1",
  gainDa1B: "К усил chB DA1",
  gainDa2A: "К усил chA DA2",
  gainDa2B: "К усил chB DA2",
  adcaC0Ctrl: "К усил АЦП А",
  adcbC0Ctrl: "К усил АЦП B",
  ustavkaL: "Уставка, о.е.",
  countValue: "Количество превышений уставки",
  porogMcf: "Порог МЦФ, о.е.",
  mcTemperature: "Температура МК, °С",
  rstStatus: "Регистр статуса МК",
  rz: "Величина последнего значения, превысившего уставку, о.е.",
  step: "dt,точка",
  gsmSignalLevel: "Сигнал GPRS модема",
  chBitRateError: "Channel Bit Rate Error",
  impulseCountCh0: "Количество импульсов Ch0",
  impulseCountCh1: "Количество импульсов Ch1",
  impulseCountCh2: "Количество импульсов Ch2",
  emergChNumber: "Аварийный канал",
  sensorFlag: "Номер точки начала аварии в Ch0 (алг. датчика)",
  sensorFlagCh1: "Номер точки начала аварии в Ch1 (алг. датчика)",
  sensorFlagCh2: "Номер точки начала аварии в Ch2 (алг. датчика)",
  flag: "Номер точк начала аварии в Ch0 (МЦФ1)",
  flagCh1: "Номер точки начала аварии в Ch1 (МЦФ1)",
  flagCh2: "Номер точки начала аварии в Ch2 (МЦФ1)",
  Imp1MaxCh0: "Максимальное значение 1-ого импульса Ch0",
  mcf2StartPointACh1: "Номер точки начала аварии в Ch1 (МЦФ2)",
  mcf2StartPointACh2: "Номер точки начала аварии в Ch2 (МЦФ2)",
  imp1MaxCh0: "Максимальное значение 1-ого импульса Ch0",
  imp1MinCh0: "Минимальное значение 1-ого импульса Ch0",
  imp1MaxPointCh0: "Номер точки макс. значения 1-ого импульса Ch0",
  imp1MinPointCh0: "Номер точки мин. значения 1-ого импульса Ch0",
  imp1MaxCh1: "Максимальное значение 1-ого импульса Ch1",
  imp1MinCh1: "Минимальное значение 1-ого импульса Ch1",
  imp1MaxPointCh1: "Номер точки макс. значения 1-ого импульса Ch1",
  imp1MinPointCh1: "Номер точки мин. значения 1-ого импульса Ch1",
  imp1MaxCh2: "Максимальное значение 1-ого импульса Ch2",
  imp1MinCh2: "Мнимальное значение 1-ого импульса Ch2",
  imp1MaxPointCh2: "Номер точки макс. значения 1-ого импульса Ch2",
  imp1MinPointCh2: "Номер точки мин. значения 1-ого импульса Ch2",
  noisePorogA: "Шум Ch0",
  noisePorogB: "Шум Ch1",
  noisePorogC: "Шум Ch2",
  imp1SpecEnergyCh0: "Удельная энергия 1 импульса Ch0",
  imp1SpecEnergyCh1: "Удельная энергия 1 импульса Ch1",
  imp1SpecEnergyCh2: "Удельная энергия 1 импульса Ch2",
  imp2SpecEnergyCh0: "Удельная энергия 2 импульса Ch0",
  imp2SpecEnergyCh1: "Удельная энергия 2 импульса Ch1",
  imp2SpecEnergyCh2: "Удельн��я энергия 2 импульса Ch2",
  imp1DurationCh0: "Длительность 1 импульса Ch0",
  imp1DurationCh1: "Длительность 1 импульса Ch1",
  imp1DurationCh2: "Длительность 1 импульса Ch2",
  imp2DurationCh0: "Длительность 2 импульса Ch0",
  imp2DurationCh1: "Длительность 2 импульса Ch1",
  imp2DurationCh2: "Длительность 2 импульса Ch2",
  corRatio01: "Коэф. корреляции между Ch0 и Ch1",
  corRatio02: "Коэф. корреляции между Ch0 и Ch2",
  corRatio12: "Коэф. корреляции между Ch1 и Ch2",
};

import DashboardContainer from "../content/new_dashboard/DashboardContainer.vue";
import AddDeviceModal from "@/view/content/new_dashboard/components/AddDeviceModal";
import AddPowerLineModal from "@/view/content/new_dashboard/components/AddPowerLineModal";
import AddScatDeviceModal from "@/view/content/new_dashboard/components/AddScatDeviceModal";

import AddingWidget from "@/view/content/new_dashboard/components/AddingWidget";
import DeviceSelection from "@/view/content/new_dashboard/DeviceSelection";
import ApiService from "@/core/services/api.service";
import { API_REQUEST } from "../../core/services/store/api.module";
import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import {
  loadSettings,
  storeSettings,
} from "@/core/services/store/dashboard/dashboard.api";
import axios from "axios";

export default {
  name: "dashboard",
  components: {
    DashboardContainer,
    DeviceSelection,
    AddDeviceModal,
    AddingWidget,
    AddPowerLineModal,
    AddScatDeviceModal,
  },
  data() {
    return {
      powerLines: [],
      enabledDevices: "",
      suggestedVariants: [],
      filterParameters: "",
      doneTypingInterval: 500,
      typingTimer: null,
      showDashboardOverlay: false,
      getList: {
        resource: "/api/device",
        requestType: "GET",
        requestParams: {
          teamId: ApiService.teamData.value,
          withPublic: true,
          size: "1000",
          page: "0",
        },
      },
      currentStep: 1,
      selectedDevice: {},
      selectedWidget: {},
      catalogue: {
        data: [],
        totalCount: 1,
      },
      addDeviceDto: {
        locationManual: true,
        lat: null,
        lng: null,
        publicAvailable: false,
        altitude: null,
        timezoneId: null,
        timezoneOffset: null,
        timezoneLastUpdateDt: null,
        locationLastUpdateDt: null,
        lastDataReceivedDt: null,
        notConnectedNotificationDt: null,
        teamName: null,
        parameterMappings: [],
        parameterMappingsToDelete: [],
        commands: [],
        commandsToDelete: [],
        id: null,
        typeName: null,
        sensorDTO: {
          countValue: null,
          porog_MCF: null,
          powerlineId: null,
          vertexId: null,
          sensorVersion: null,
          porogArtInt: null,
          zeroNumber: null,
          gainDA1_A: null,
          gainDA1_B: null,
          gainDA2_A: null,
          gainDA2_B: null,
          program_Change: null,
          step: null,
          time_period: null,
          ustavka_L: null,
          isVoltageCircuit: null,
        },
      },
      devices: {
        data: [],
        totalCount: 1,
      },
      steps: [
        { label: this.$t("NEW_DASHBOARD.STEP_1"), value: 1 },
        { label: this.$t("NEW_DASHBOARD.STEP_2"), value: 2 },
        { label: this.$t("NEW_DASHBOARD.STEP_3"), value: 3 },
      ],
      response: {
        data: [],
        totalCount: 1,
      },
      layouts: {
        desktop: [],
        tablet: [],
        mobile: [],
      },
      draggable: false,
      resizable: false,
      isLoading: false,
    };
  },
  watch: {
    'layouts.desktop': {
      handler() {
        this.updateActions();
      },
      immediate: true,
    },
  },

  computed: {
    actions() {
      if (this.layouts.desktop.length > 0) {
        return [
          {
            label: this.$t("Участки"),
            action: this.onCreateNewAction,
            icon: "fas fa-plus",
          },
          {
            label: this.$t("DASHBOARD.CLEAR"),
            action: this.clearDashboard,
            icon: "fas fa-trash",
          },
        ];
      } else {
        return [
          {
            label: this.$t("Участки"),
            action: this.onCreateNewAction,
            icon: "fas fa-plus",
          },
        ];
      }
    },
  },

  async mounted() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.loadDeviceList(),
        this.loadWidgets(),
        this.loadCatalogue(),
        this.loadPowerLines(),
      ]);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.LINE_MONITORING") },
      ]);
    } catch (error) {
      this.onError(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    updateActions() {
      this.$store.dispatch(SET_ACTIONS, this.actions);
    },
    onCreateNewAction: function () {
      this.$router.push({ name: "segments" });
    },
    onError(error) {
      let message = this.$t("COMMON.UNKNOWN_ERROR");

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (error.request) {
        message = this.$t("COMMON.NETWORK_ERROR");
      }

      this.$bvToast.toast(message, {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });
      this.isLoading = false;
    },
    async addPowerLine(form) {
      this.showDashboardOverlay = true;

      try {
        await axios.post(`api/oscillogram/powerline`, {
          ...form,
          teamId: ApiService.teamData.value
        });
        await this.loadPowerLines();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },
    async removePowerLine(id) {
      this.showDashboardOverlay = true;
      try {
        await axios.delete(`api/oscillogram/powerline/${id}/delete`);
        await this.loadPowerLines();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async addDevice({ form }) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`api/device/save`, {
          additionalDevices: form.additionalDevices,
          mainDevice: form.mainDevice,
          typeId: form.type,
          phoneNumber: form.phone,
          password: form.password,
          active: form.active,
          teamId: ApiService.teamData.value,
          imei: form.imei,
          name: form.name,
          ...this.addDeviceDto,
        });
        await this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    generateUUIDv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    async addScatDevice(form) {
      this.showDashboardOverlay = true;
      try {
        const { sensorDTO, ...rest } = this.addDeviceDto;
        await axios.post(`api/device/save`, {
          additionalDevices: "",
          mainDevice: false,
          typeId: "297e9ce2-9134-4004-b67f-21c1683aaede",
          phoneNumber: form?.phone ? form.phone : "",
          password: form.password,
          active: true,
          teamId: ApiService.teamData.value,
          imei: "scat" + this.generateUUIDv4(),
          name: form.name,
          sensorDTO: {
            ...form,
          },
          ...rest,
        });
        await this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async handleDeleteDevice(id) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`/api/device/${id}/delete`);
        await this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async loadCatalogue() {
      this.showDashboardOverlay = true;
      try {
        // Ваш асинхронный код для загрузки каталога
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },
    async loadPowerLines() {
      this.showDashboardOverlay = true;
      try {
        const templateResponse = await axios.get(`api/oscillogram/powerline`, {
          params: {
            teamId: this.getList.requestParams.teamId,
          },
        });
        this.powerLines = Object.values(templateResponse.data).map(
          (powerLine) => ({
            ...powerLine,
            available_widgets: [
              {
                id: "e2f3341-0008-3434523426d-43-5d51986df3e1",
                name: "Показания датчиков",
                parameters: {},
                interval: "MONTH",
                type: "ScatIndications",
                isCustom: false,
              },
              {
                id: "e2f34791-0008-23451986e1",
                name: "Аварийные синхронные осциллограммы",
                parameters: {},
                interval: "MONTH",
                type: "ASO",
                isCustom: false,
              },
            ],
          })
        );
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async loadDeviceList() {
      this.showDashboardOverlay = true;
      try {
        const response = await this.$store.dispatch(API_REQUEST, this.getList);
        if (response && response.content) {
          const templateRequests = response.content.map(async (item) => {
            try {
              if (item.typeId === "297e9ce2-9134-4004-b67f-21c1683aaede") {
                return {
                  ...item,
                  available_widgets: [
                    {
                      id: "e2f34791-0008-4234234f3e1",
                      name: "Временные осциллограммы изменения",
                      parameters: scatParameters,
                      interval: "MONTH",
                      type: "LinearSkat",
                      isScat: true,
                      isCustom: false,
                    },
                  ],
                };
              } else {
                const templateResponse = await axios.get(
                  `api/widget-template/getAvailableTemplates?deviceId=${item.id}`
                );

                return {
                  ...item,
                  available_widgets: templateResponse.data || [],
                };
              }
            } catch (error) {
              this.onError(error);
              return {
                ...item,
                available_widgets: [],
              };
            }
          });

          const devicesWithTemplates = await Promise.all(templateRequests);

          this.devices = {
            ...response.content,
            data: devicesWithTemplates,
          };

          if (this.selectedDevice.id) {
            const updatedDevice = this.devices.data.find(
              (device) => device.id === this.selectedDevice.id
            );
            if (updatedDevice) {
              this.selectedDevice = updatedDevice;
            }
          }
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async loadWidgets() {
      this.showDashboardOverlay = true;
      try {
        const teamId = ApiService.teamData.value;
        const settings = await loadSettings(teamId, "skat");
        if (settings) {
          this.layouts = {
            desktop: settings.layouts?.desktop || [],
            tablet: settings.layouts?.tablet || [],
            mobile: settings.layouts?.mobile || [],
          };
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async clearDashboard() {
      this.showDashboardOverlay = true;
      try {
        const data = { layouts: { desktop: [], tablet: [], mobile: [] } };
        this.layouts = { desktop: [], tablet: [], mobile: [] };
        await storeSettings(data, "skat");
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async saveWidgets() {
      this.showDashboardOverlay = true;
      try {
        const data = { layouts: this.layouts };
        await storeSettings(data, "skat");
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async handleAddTemplate(template) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(
          `/api/widget-template/save?deviceId=${template?.deviceId}`,
          template
        );
        this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async handleDeleteTemplate(id) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`/api/widget-template/${id}/delete`);
        this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    removeWidget(widgetId) {
      try {
        this.layouts.desktop = this.layouts.desktop.filter(widget => widget.i !== widgetId);
        this.layouts.tablet = this.layouts.tablet.filter(widget => widget.i !== widgetId);
        this.layouts.mobile = this.layouts.mobile.filter(widget => widget.i !== widgetId);
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    updateWidget(widget) {
      try {
        ['desktop', 'tablet', 'mobile'].forEach(layout => {
          const widgetIndex = this.layouts[layout].findIndex(w => w.i === widget.i);
          if (widgetIndex !== -1) {
            const updatedWidget = { ...this.layouts[layout][widgetIndex], ...widget };
            updatedWidget.x = this.layouts[layout][widgetIndex].x;
            updatedWidget.y = this.layouts[layout][widgetIndex].y;
            updatedWidget.w = this.layouts[layout][widgetIndex].w;
            updatedWidget.h = this.layouts[layout][widgetIndex].h;
            this.$set(this.layouts[layout], widgetIndex, updatedWidget);
          }
        });
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    onLayoutUpdated({ layout, deviceType }) {
      try {
        this.layouts[deviceType] = layout;
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    onLayoutReady(_e) {
      this.saveWidgets();
    },

    handleDevices(device) {
      this.enabledDevices = device;
    },

    handleSuggestedVariant(suggestedVariant) {
      this.selectedDevice = suggestedVariant;
    },

    handleAddWidget(templateData) {
      try {
        this.addWidgetOnDashboard({
          widget: templateData,
          deviceId: templateData.deviceId,
        });
      } catch (error) {
        this.onError(error);
      }
    },

    addWidgetOnDashboard({ widget, deviceId }) {
      try {
        const newWidgetId = `widget_${Date.now()}`;
        let newWidget;

        if (widget.isLep) {
          const powerLine = this.powerLines.find((line) => line.id === deviceId);
          newWidget = {
            ...widget,
            deviceId,
            deviceName: powerLine.name,
            isLep: true,
            i: newWidgetId
          };
        } else {
          const device = this.devices.data.find((item) => item.id === deviceId);
          newWidget = {
            ...widget,
            deviceId,
            deviceName: device.name,
            i: newWidgetId
          };
        }

        ['desktop', 'tablet', 'mobile'].forEach(deviceType => {
          // Shift existing widgets down
          this.layouts[deviceType] = this.layouts[deviceType].map(widget => ({
            ...widget,
            y: widget.y + 20
          }));

          // Add new widget at the top
          const widgetForDevice = {
            ...newWidget,
            x: 0,
            y: 0,
            w: deviceType === 'desktop' ? 12 : 1,
            h: 20
          };

          this.layouts[deviceType].unshift(widgetForDevice);
        });

        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    updateWidgets(widgets) {
      // Handle widget updates if needed
      this.saveWidgets();
    },
  },
};
</script>

<style scoped>
.overlay-dashboard {
  height: 100%;
}

.dashboard {
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: rgba(238, 240, 248, 1);
  height: 82vh;
  position: relative;
}

.loader-position {
  position: absolute;
  left: 0;
  z-index: 20;
}

.empty_dashboard_view {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.empty_dashboard_view_settings {
  flex: 1;
}

.empty_dashboard_view_footer {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 2rem;
}

.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
}
</style>
