<template>
  <!-- <div class="dashboard"> -->
  <b-overlay :show="isLoading || showDashboardOverlay" spinner small rounded="sm" class="overlay-dashboard">
    <DeviceSelection v-if="!layouts.desktop.length" :devices="devices.data" :powerLines="powerLines" :steps="steps"
      :enabledDevices="enabledDevices" :suggestedVariants="devices.data" :selectedDevice="selectedDevice"
      :selectedWidget="selectedWidget" @handleDevices="handleDevices" @suggestedVariant="handleSuggestedVariant"
      @addDevice="addDevice" @addTemplate="handleAddTemplate" @addWidget="addWidgetOnDashboard"
      @deleteTemplate="handleDeleteTemplate" @deleteDevice="handleDeleteDevice" />
    <dashboard-container v-if="layouts.desktop.length" ref="dashboardContainer" :layouts="layouts" :devices="devices"
      :catalogue="catalogue" :powerLines="powerLines" :isLoading="isLoading" :path="path"
      @update-widgets="updateWidgets" @save-widgets="saveWidgets" @remove-widget="removeWidget"
      @add-widget="addWidgetOnDashboard" @update-widget="updateWidget" @addTemplate="handleAddTemplate"
      @deleteTemplate="handleDeleteTemplate" @deleteDevice="handleDeleteDevice" @layout-ready="onLayoutReady"
      @layout-updated="onLayoutUpdated" />
    <AddDeviceModal ref="addDeviceModal" @addDevice="addDevice" />
    <AddingWidget @addWidget="handleAddWidget" :devices="devices.data" ref="addTemplateModal" />
  </b-overlay>
  <!-- </div> -->
</template>

<script>
import DashboardContainer from "../content/new_dashboard/DashboardContainer.vue";
import AddDeviceModal from "@/view/content/new_dashboard/components/AddDeviceModal";

import AddingWidget from "@/view/content/new_dashboard/components/AddingWidget";
import DeviceSelection from "@/view/content/new_dashboard/DeviceSelection";
import ApiService from "@/core/services/api.service";
import { API_REQUEST } from "../../core/services/store/api.module";
import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import {
  loadSettings,
  storeSettings,
} from "@/core/services/store/dashboard/dashboard.api";
import axios from "axios";

export default {
  name: "dashboard",
  components: {
    DashboardContainer,
    DeviceSelection,
    AddDeviceModal,
    AddingWidget,
  },
  data() {
    return {
      powerLines: [],
      enabledDevices: "",
      suggestedVariants: [],
      filterParameters: "",
      doneTypingInterval: 500,
      typingTimer: null,
      showDashboardOverlay: false,
      getList: {
        resource: "/api/device",
        requestType: "GET",
        requestParams: {
          teamId: ApiService.teamData.value,
          withPublic: true,
          size: "1000",
          page: "0",
        },
      },
      currentStep: 1,
      selectedDevice: {},
      selectedWidget: {},
      catalogue: {
        data: [],
        totalCount: 1,
      },
      addDeviceDto: {
        locationManual: true,
        lat: null,
        lng: null,
        publicAvailable: false,
        altitude: null,
        timezoneId: null,
        timezoneOffset: null,
        timezoneLastUpdateDt: null,
        locationLastUpdateDt: null,
        lastDataReceivedDt: null,
        notConnectedNotificationDt: null,
        teamName: null,
        parameterMappings: [],
        parameterMappingsToDelete: [],
        commands: [],
        commandsToDelete: [],
        id: null,
        typeName: null,
        sensorDTO: {
          countValue: null,
          porog_MCF: null,
          powerlineId: null,
          vertexId: null,
          sensorVersion: null,
          porogArtInt: null,
          zeroNumber: null,
          gainDA1_A: null,
          gainDA1_B: null,
          gainDA2_A: null,
          gainDA2_B: null,
          program_Change: null,
          step: null,
          time_period: null,
          ustavka_L: null,
          isVoltageCircuit: null,
        },
      },
      devices: {
        data: [],
        totalCount: 1,
      },
      steps: [
        { label: this.$t("NEW_DASHBOARD.STEP_1"), value: 1 },
        { label: this.$t("NEW_DASHBOARD.STEP_2"), value: 2 },
        { label: this.$t("NEW_DASHBOARD.STEP_3"), value: 3 },
      ],
      response: {
        data: [],
        totalCount: 1,
      },
      widgets: [],
      layouts: {
        desktop: [],
        tablet: [],
        mobile: [],
      },
      draggable: false,
      resizable: false,
      isLoading: false,
    };
  },
  watch: {
    'layouts.desktop': {
      handler() {
        this.updateActions();
      },
      immediate: true,
    },
  },

  computed: {
    actions() {
      if (this.layouts?.desktop.length > 0) {
        return [
          {
            label: this.$t("DASHBOARD.CLEAR"),
            action: this.clearDashboard,
            icon: "fas fa-trash",
          },
        ];
      } else {
        return [];
      }
    },
  },

  async mounted() {
    this.isLoading = true;
    try {
      await Promise.all([this.loadDeviceList(), this.loadWidgets()]);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MENU.DASHBOARD") },
      ]);
    } catch (error) {
      this.onError(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    updateActions() {
      this.$store.dispatch(SET_ACTIONS, this.actions);
    },
    onCreateNewAction: function () {
      this.$router.push({ name: "segments" });
    },
    onError(error) {
      let message = this.$t("COMMON.UNKNOWN_ERROR");

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      } else if (error.message) {
        message = error.message;
      } else if (error.request) {
        message = this.$t("COMMON.NETWORK_ERROR");
      }

      this.$bvToast.toast(message, {
        title: this.$t("COMMON.ERROR"),
        variant: "danger",
        autoHideDelay: 5000,
      });
      this.isLoading = false;
    },

    async addDevice({ form }) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`api/device/save`, {
          additionalDevices: form.additionalDevices,
          mainDevice: form.mainDevice,
          typeId: form.type,
          phoneNumber: form.phone,
          password: form.password,
          active: form.active,
          teamId: ApiService.teamData.value,
          imei: form.imei,
          name: form.name,
          ...this.addDeviceDto,
        });
        await this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    generateUUIDv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    async handleDeleteDevice(id) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`/api/device/${id}/delete`);
        await this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async loadDeviceList() {
      this.showDashboardOverlay = true;
      try {
        const response = await this.$store.dispatch(API_REQUEST, this.getList);
        if (response && response.content) {
          const templateRequests = response.content.map(async (item) => {
            try {
              const templateResponse = await axios.get(
                `api/widget-template/getAvailableTemplates?deviceId=${item.id}`
              );
              return {
                ...item,
                available_widgets: templateResponse.data || [],
              };
            } catch (error) {
              this.onError(error);
              return {
                ...item,
                available_widgets: [],
              };
            }
          });

          const devicesWithTemplates = await Promise.all(templateRequests);

          this.devices = {
            ...response.content,
            data: devicesWithTemplates,
          };

          if (this.selectedDevice.id) {
            const updatedDevice = this.devices.data.find(
              (device) => device.id === this.selectedDevice.id
            );
            if (updatedDevice) {
              this.selectedDevice = updatedDevice;
            }
          }
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async loadWidgets() {
      this.showDashboardOverlay = true;
      try {
        const teamId = ApiService.teamData.value;
        const settings = await loadSettings(teamId, "meteo");
        if (settings) {
          this.layouts = {
            desktop: settings.layouts?.desktop || [],
            tablet: settings.layouts?.tablet || [],
            mobile: settings.layouts?.mobile || [],
          };
        }
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async clearDashboard() {
      this.showDashboardOverlay = true;
      try {
        const data = { layouts: { desktop: [], tablet: [], mobile: [] } };
        this.layouts = { desktop: [], tablet: [], mobile: [] };
        await storeSettings(data, "meteo");
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },


    async saveWidgets() {
      try {
        const data = { layouts: this.layouts };
        await storeSettings(data, "meteo");
      } catch (error) {
        this.onError(error);
      }
    },

    async handleAddTemplate(template) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(
          `/api/widget-template/save?deviceId=${template?.deviceId}`,
          template
        );
        this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    async handleDeleteTemplate(id) {
      this.showDashboardOverlay = true;
      try {
        await axios.post(`/api/widget-template/${id}/delete`);
        this.loadDeviceList();
      } catch (error) {
        this.onError(error);
      } finally {
        this.showDashboardOverlay = false;
      }
    },

    removeWidget(widgetId) {
      try {
        this.layouts.desktop = this.layouts.desktop.filter(widget => widget.i !== widgetId);
        this.layouts.tablet = this.layouts.tablet.filter(widget => widget.i !== widgetId);
        this.layouts.mobile = this.layouts.mobile.filter(widget => widget.i !== widgetId);
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    updateWidget(widget) {
      try {
        ['desktop', 'tablet', 'mobile'].forEach(layout => {
          const widgetIndex = this.layouts[layout].findIndex(w => w.i === widget.i);
          if (widgetIndex !== -1) {
            const updatedWidget = { ...this.layouts[layout][widgetIndex], ...widget };
            updatedWidget.x = this.layouts[layout][widgetIndex].x;
            updatedWidget.y = this.layouts[layout][widgetIndex].y;
            updatedWidget.w = this.layouts[layout][widgetIndex].w;
            updatedWidget.h = this.layouts[layout][widgetIndex].h;
            this.$set(this.layouts[layout], widgetIndex, updatedWidget);
          }
        });
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    onLayoutUpdated({ layout, deviceType }) {
      try {
        this.layouts[deviceType] = layout;
        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },

    onLayoutReady(_e) {
      this.saveWidgets();
    },

    handleDevices(device) {
      this.enabledDevices = device;
    },

    handleSuggestedVariant(suggestedVariant) {
      this.selectedDevice = suggestedVariant;
    },

    handleAddWidget(templateData) {
      try {
        this.addWidgetOnDashboard({
          widget: templateData,
          deviceId: templateData.deviceId,
        });
      } catch (error) {
        this.onError(error);
      }
    },

    addWidgetOnDashboard({ widget, deviceId }) {
      try {
        const newWidgetId = `widget_${Date.now()}`;
        let newWidget;

        if (widget.isLep) {
          const powerLine = this.powerLines.find((line) => line.id === deviceId);
          newWidget = {
            ...widget,
            deviceId,
            deviceName: powerLine.name,
            isLep: true,
            i: newWidgetId
          };
        } else {
          const device = this.devices.data.find((item) => item.id === deviceId);
          newWidget = {
            ...widget,
            deviceId,
            deviceName: device.name,
            i: newWidgetId
          };
        }

        ['desktop', 'tablet', 'mobile'].forEach(deviceType => {
          this.layouts[deviceType] = this.layouts[deviceType].map(widget => ({
            ...widget,
            y: widget.y + 20
          }));

          const widgetForDevice = {
            ...newWidget,
            x: 0,
            y: 0,
            w: deviceType === 'desktop' ? 12 : 1,
            h: 20
          };

          this.layouts[deviceType].unshift(widgetForDevice);
        });

        this.saveWidgets();
      } catch (error) {
        this.onError(error);
      }
    },
  },
};
</script>

<style scoped>
.overlay-dashboard {
  height: 100%;
}

.dashboard {
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: rgba(238, 240, 248, 1);
  height: 82vh;
  position: relative;
}

.loader-position {
  position: absolute;
  left: 0;
  z-index: 20;
}

.empty_dashboard_view {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.empty_dashboard_view_settings {
  flex: 1;
}

.empty_dashboard_view_footer {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 2rem;
}

.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
}
</style>
