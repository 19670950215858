<template>
  <div>
    <template v-if="path === 'skat'">
      <div class="devices-block">
        <p class="devices-title">
          {{ $t("NEW_DASHBOARD.POWERLINE") }}
          <VisibleToggleButton
            :set-visible="togglePowerlineVisibility"
            :is-visible="powerlineVisible"
          />
        </p>
        <div v-if="powerlineVisible">
          <DeviceButton
            v-for="powerLine in powerLines"
            :key="powerLine.id"
            :device="powerLine"
            :widget-list="scatWidgetList"
            @widget-selected="selectWidget"
          />
        </div>
      </div>

      <div class="devices-block public">
        <p class="devices-title">
          {{ $t("NEW_DASHBOARD.SKAT_DEVICES") }}
          <VisibleToggleButton
            :set-visible="toggleSkatDevicesVisibility"
            :is-visible="skatDevicesVisible"
          />
        </p>
        <div v-if="skatDevicesVisible">
          <DeviceButton
            v-for="device in devices.filter(
              (device) => device.typeId === '297e9ce2-9134-4004-b67f-21c1683aaede'
            )"
            :key="device.id"
            :device="device"
            :widget-list="scatDevicesWidgetList"
            @widget-selected="selectWidget"
            @addTemplate="$emit('addTemplate', $event)"
            @deleteTemplate="$emit('deleteTemplate', $event)"
            @deleteDevice="$emit('deleteDevice', $event)"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="devices-block">
        <p class="devices-title">
          {{ $t("NEW_DASHBOARD.MY_DEVICES") }}
          <VisibleToggleButton
            :set-visible="toggleMyDevicesVisibility"
            :is-visible="myDevicesVisible"
          />
        </p>
        <div v-if="myDevicesVisible">
          <DeviceButton
            v-for="device in devices.filter(
              (device) =>
                !device.publicAvailable &&
                device.typeId !== '297e9ce2-9134-4004-b67f-21c1683aaede'
            )"
            :key="device.id"
            :device="device"
            :widget-list="widgetList"
            @widget-selected="selectWidget"
            @addTemplate="$emit('addTemplate', $event)"
            @deleteTemplate="$emit('deleteTemplate', $event)"
            :canAddTemplate="true"
          />
        </div>
      </div>

      <div class="devices-block public">
        <p class="devices-title">
          {{ $t("NEW_DASHBOARD.PUBLIC_DEVICES") }}
          <VisibleToggleButton
            :set-visible="togglePublicDevicesVisibility"
            :is-visible="publicDevicesVisible"
          />
        </p>
        <div v-if="publicDevicesVisible">
          <DeviceButton
            v-for="device in devices.filter((device) => device.publicAvailable)"
            :key="device.id"
            :device="device"
            :widget-list="widgetList"
            @widget-selected="selectWidget"
            @addTemplate="$emit('addTemplate', $event)"
            @deleteTemplate="$emit('deleteTemplate', $event)"
            :canAddTemplate="true"
            @deleteDevice="$emit('deleteDevice', $event)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { widgetList } from "../libs/widget-list";
import DeviceButton from "./DeviceButton.vue";
import VisibleToggleButton from "./ToogleEyeButton.vue";

export default {
  components: {
    DeviceButton,
    VisibleToggleButton,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    catalogue: {
      type: Array,
      required: true,
    },
    powerLines: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      widgetList: widgetList.devicesWidgetList,
      scatWidgetList: widgetList.scatWidgetList,
      scatDevicesWidgetList: widgetList.scatDeviceWidgetList,
      myDevicesVisible: true,
      publicDevicesVisible: true,
      powerlineVisible: true,
      skatDevicesVisible: true,
    };
  },
  methods: {
    selectWidget({ deviceId, widget }) {
      this.$emit("widget-selected", { deviceId, widget });
    },
    addLepWidget(lep) {
      this.$emit("widget-selected", {
        deviceId: lep.id,
        widget: lep,
        isLep: true,
      });
    },
    toggleMyDevicesVisibility(v) {
      this.myDevicesVisible = v;
    },
    togglePublicDevicesVisibility(v) {
      this.publicDevicesVisible = v;
    },
    togglePowerlineVisibility(v) {
      this.powerlineVisible = v;
    },
    toggleSkatDevicesVisibility(v) {
      this.skatDevicesVisible = v;
    },
  },
};
</script>

<style scoped>
.devices-block {
  margin-top: 20px;
}

.devices-title {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.85px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #6c7392;
  display: flex;
  justify-content: space-between;
}

.public {
  margin-top: 30px;
  border-top: 1.5px solid rgba(181, 181, 194, 0.5);
  padding-top: 25px;
}
</style>
