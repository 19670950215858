<template>
  <div class="empty_dashboard_view">
    <div class="empty_dashboard_view_settings">
      <dashboard-tabs
        :tabs="steps"
        :currentTab="currentStep"
        :setActiveTab="() => {}"
      />
      <!-- path === 'skat' -->
      <div v-if="currentStep === 1" class="empty_dashboard_view_buttons">
        <template v-if="path === 'skat'">
          <CardButton
            :active="enabledDevices === 'powerlines'"
            imageUrl="media/svg/buttons/powerline_icon.svg"
            :onClick="() => handleDevice('powerlines')"
            :text="$t('NEW_DASHBOARD.POWERLINE')"
          />
          <CardButton
            v-if="powerLines.length > 0"
            :active="enabledDevices === 'scat'"
            imageUrl="media/svg/buttons/my_devices_icon.svg"
            :onClick="() => handleDevice('scat')"
            :text="$t('NEW_DASHBOARD.SKAT_DEVICES')"
          />
        </template>
        <template v-else>
          <CardButton
            :active="enabledDevices === 'public'"
            imageUrl="media/svg/buttons/public_devices_icon.svg"
            :onClick="() => handleDevice('public')"
            :text="$t('NEW_DASHBOARD.PUBLIC_DEVICES')"
          />
          <CardButton
            :active="enabledDevices === 'my'"
            imageUrl="media/svg/buttons/my_devices_icon.svg"
            :onClick="() => handleDevice('my')"
            :text="$t('NEW_DASHBOARD.MY_DEVICES')"
          />
        </template>
      </div>
      <div v-if="currentStep === 2" class="empty_dashboard_view_buttons">
        <CardButton
          v-for="device in filteredDevices"
          :active="device.id === selectedDevice?.id"
          :key="device.id"
          :imageUrl="
            deviceTypes[device.typeId]
              ? deviceTypes[device.typeId].icon
              : 'media/devices/sokol_tdv.svg'
          "
          :onClick="() => handleSuggestedVariant(device)"
          :text="device.name"
        >
          <template v-slot:delete-button>
            <ConfirmDeleteModal
              v-if="enabledDevices !== 'public'"
              :item-id="device.id"
              :on-delete="
                () => {
                  if (enabledDevices === 'powerlines') {
                    return $emit('removePowerLine', device.id);
                  }
                  return $emit('deleteDevice', device.id);
                }
              "
            >
              <template #button>
                <button class="remove-btn">
                  <img src="media/svg/buttons/delete-blue.svg" alt="Delete" />
                </button>
              </template>
            </ConfirmDeleteModal>
          </template>
        </CardButton>

        <AddPowerLineModal
          v-if="enabledDevices === 'powerlines'"
          @addPowerLine="$emit('addPowerLine', $event)"
        >
          <template #open-modal-button="{ openModal }">
            <CardButton
              imageUrl="media/svg/buttons/add_device_dashboard.svg"
              :onClick="openModal"
              :text="$t('NEW_DASHBOARD.NEW_POWERLINE')"
            />
          </template>
        </AddPowerLineModal>
        <AddScatDeviceModal
          :powerLines="powerLines"
          v-if="enabledDevices === 'scat' && powerLines.length > 0"
          @addScatDevice="$emit('addScatDevice', $event)"
        >
          <template #open-modal-button="{ openModal }">
            <CardButton
              imageUrl="media/svg/buttons/add_device_dashboard.svg"
              :onClick="openModal"
              :text="$t('NEW_DASHBOARD.NEW_DEVICE')"
            />
          </template>
        </AddScatDeviceModal>
        <AddDeviceModal
          v-if="enabledDevices === 'my'"
          @addDevice="$emit('addDevice', $event)"
        >
          <template #open-modal-button="{ openModal }">
            <CardButton
              imageUrl="media/svg/buttons/add_device_dashboard.svg"
              :onClick="openModal"
              :text="$t('NEW_DASHBOARD.NEW_DEVICE')"
            />
          </template>
        </AddDeviceModal>
      </div>
      <div v-if="currentStep === 3" class="empty_dashboard_view_buttons">
        <CardButton
          v-for="template in selectedDevice.available_widgets"
          :key="template.id"
          :active="template.widget?.i === selectedWidget?.widget?.i"
          :imageUrl="widgetList?.[template.type].svg"
          :onClick="
            () =>
              addWidgetOnDashboard({
                widget: {
                  ...template,
                  isLep: enabledDevices === 'powerlines' ? true : false,
                },
                deviceId: selectedDevice?.id,
              })
          "
          :text="
            template.isCustom
              ? template.name
              : $t(widgetList?.[template.type].name)
          "
        >
          <template v-slot:delete-button>
            <ConfirmDeleteModal
              v-if="template.isCustom"
              :item-id="template.id"
              :on-delete="() => $emit('deleteTemplate', template.id)"
            >
              <template #button>
                <button class="remove-btn">
                  <img src="media/svg/buttons/delete-blue.svg" alt="Delete" />
                </button>
              </template>
            </ConfirmDeleteModal>
          </template>
        </CardButton>

        <AddingTemplate
          v-if="enabledDevices !== 'powerlines' && enabledDevices !== 'scat'"
          :deviceId="selectedDevice?.id"
          @addTemplate="$emit('addTemplate', $event)"
        >
          <template #open-modal-button="{ openModal }">
            <CardButton
              imageUrl="media/svg/buttons/add_device_dashboard.svg"
              :onClick="openModal"
              :text="$t('NEW_DASHBOARD.NEW_TEMPLATE')"
            />
          </template>
        </AddingTemplate>
      </div>
    </div>
    <div class="empty_dashboard_view_footer">
      <button
        v-if="currentStep > 1"
        type="button"
        class="btn prev font-weight-bold text-uppercase px-9 py-4 mr-2"
        @click="prevStep"
      >
       {{ $t('NEW_DASHBOARD.BACK') }}
      </button>
    </div>
  </div>
</template>

<script>
import DashboardTabs from "@/view/content/new_dashboard/components/DashboardTabs";
import CardButton from "@/view/content/new_dashboard/components/CardButton";
import AddingTemplate from "@/view/content/new_dashboard/components/AddingTemplate";
import AddDeviceModal from "@/view/content/new_dashboard/components/AddDeviceModal";
import AddScatDeviceModal from "@/view/content/new_dashboard/components/AddScatDeviceModal";
import AddPowerLineModal from "@/view/content/new_dashboard/components/AddPowerLineModal";

import { widgetList } from "./libs/widget-list";
import { deviceTypes } from "./libs/devices";
import ConfirmDeleteModal from "./components/ConfirmDeleteModal.vue";

export default {
  name: "DeviceSelection",
  components: {
    DashboardTabs,
    CardButton,
    AddingTemplate,
    AddDeviceModal,
    ConfirmDeleteModal,
    AddScatDeviceModal,
    AddPowerLineModal,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    powerLines: {
      type: Array,
      required: true,
    },
    selectedDevice: {
      type: Object,
      required: true,
    },
    selectedWidget: {
      type: Object,
      required: true,
    },
    enabledDevices: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: false,
      default: () => [
        { label: this.$t("NEW_DASHBOARD.STEP_1"), value: 1 },
        { label: this.$t("NEW_DASHBOARD.STEP_2"), value: 2 },
        { label: this.$t("NEW_DASHBOARD.STEP_3"), value: 3 },
      ],
    },
    suggestedVariants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 1,
      widgetList: {
        ...widgetList.devicesWidgetList,
        ...widgetList.scatWidgetList,
      },
      deviceTypes,
    };
  },
  computed: {
    filteredDevices() {
      if (this.enabledDevices === "powerlines") {
        return this.powerLines;
      }
      if (this.enabledDevices === "public") {
        return this.devices.filter(
          (device) =>
            device.publicAvailable === true &&
            device.typeId !== "297e9ce2-9134-4004-b67f-21c1683aaede"
        );
      } else if (this.enabledDevices === "my") {
        return this.devices.filter(
          (device) =>
            device.publicAvailable === false &&
            device.typeId !== "297e9ce2-9134-4004-b67f-21c1683aaede"
        );
      } else if (this.enabledDevices === "scat") {
        return this.devices.filter(
          (device) => device.typeId === "297e9ce2-9134-4004-b67f-21c1683aaede"
        );
      }
      return this.devices;
    },
  },
  methods: {
    setActiveStep(step) {
      this.currentStep = step.value;
    },
    nextStep() {
      if (
        this.currentStep === 1 &&
        (!this.enabledDevices || this.enabledDevices.length === 0)
      ) {
        alert("Выберите тип устройства");
        return;
      }

      if (
        this.currentStep === 2 &&
        (!this.selectedDevice || Object.keys(this.selectedDevice).length === 0)
      ) {
        alert("Выберите устройство");
        return;
      }

      this.currentStep = this.currentStep === 3 ? 1 : this.currentStep + 1;
    },

    prevStep() {
      this.currentStep = this.currentStep - 1;
    },
    handleDevice(device) {
      this.setActiveStep(this.steps[1]);
      this.$emit("handleDevices", device);
    },
    handleSuggestedVariant(suggestedVariant) {
      this.setActiveStep(this.steps[2]);
      this.$emit("suggestedVariant", suggestedVariant);
    },
    addWidgetOnDashboard({ widget, deviceId }) {
      this.$emit("addWidget", { widget, deviceId });
    },
    handleAddDevice() {
      this.$emit("addDevice");
    },
    handleAddTemplate() {
      this.$emit("addTemplate");
    },
  },
};
</script>

<style scoped>
.empty_dashboard_view {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.empty_dashboard_view_settings {
  flex: 1;
  overflow-y: scroll;
}
.empty_dashboard_view_footer {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 2rem;
}
.empty_dashboard_view_buttons {
  padding: 1.15rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.15rem;
}
.card_button {
  width: 217px;
  height: 217px;
  border-radius: 1rem;
  background: #fff;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;
  border: 2px solid transparent;
  transition: 0.3s;
}
.card_button:hover {
  border-color: #ff006a71;
}
.card_button_text {
  font-weight: 600;
  font-size: 13px;
  line-height: 15.5px;
}

.prev {
  background-color: #ffffff;
  color: #64cff2;
  border: 2px solid #64cff2;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem 2.25rem;
  margin-right: 0.5rem;
  transition: 0.3s;
  cursor: pointer;
}

.prev:hover {
  background-color: #64cff2;
  color: #ffffff;
}

.next {
  background-color: #64cff2;
  color: #ffffff;
  border: 2px solid transparent;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem 2.25rem;
  transition: 0.3s;
  cursor: pointer;
}

.next:hover {
  background-color: #50b2d3;
}
</style>
