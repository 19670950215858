<template>
  <div>
    <slot name="open-modal-button" :openModal="openModal"></slot>
    <b-modal id="modal-1" ref="modal" size="xl" :title="$t('NEW_DASHBOARD.NEW_DEVICE')" hide-footer>
      <AddScatDeviceForm ref="form" :powerLines="powerLines" :sensorData="sensorData" @submit="handleSubmit" />
      <div class="d-flex justify-content-end mt-4">
        <button class="btn btn-secondary me-2" @click="closeModal">{{ $t('NEW_DASHBOARD.CANCEL') }}</button>
        <button class="btn btn-primary" @click="submitForm">{{ $t('NEW_DASHBOARD.NEW_DEVICE') }}</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AddScatDeviceForm from "./AddScatDeviceForm";

export default {
  name: "AddScatDeviceModal",
  components: {
    AddScatDeviceForm,
  },
  props: {
    powerLines: {
      type: Array,
      required: true,
    },
    sensorData: {
      type: Object,
      default: () => ({
        id: null,
        sensorVersion: null,
        time_period: 900,
        powerlineId: null,
        vertexId: null,
        ustavka_L: 15,
        gainDA1_A: 1,
        gainDA1_B: 1,
        gainDA2_A: 1,
        gainDA2_B: 1,
        porog_MCF: null,
        porogArtInt: null,
        zeroNumber: null,
        step: null,
        isVoltageCircuit: 1,
        countValue: null,
      }),
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.show();
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    submitForm() {
      this.$refs.form.submitForm();
    },
    handleSubmit(data) {
      this.$emit("addScatDevice", data);
      this.closeModal();
    }
  },
};
</script>
